import React, { useEffect, useState } from 'react';

import SelectionCards from "../Cards/SelectionCards";

import useEmblaCarousel from 'embla-carousel-react'
import { getCompositions } from "../../../lib";
import { isLocalHost } from '../../../config';
export default function CollectionServices() {
  const [isMobile, setIsMobile] = useState(false);
  const [cardData, setCardData] = useState([
  ]);
  const [initData, setInitData] = useState(true);
  useEffect(() => {
      getCompositions().then(result => {
        setCardData(result.data)
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [initData]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 781);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [emblaRef] = useEmblaCarousel()

  return (
    <div className="section-card-service">
      <h1 className="titre-collection">Nos créations originales</h1>
      <h2 className="sous-titre-collection">Découvrez notre collection de fleurs, plantes et cadeaux uniques !</h2>
      <h2 className="sous-titre-collection-2">{`Les bouquets étant réalisés uniquement avec des fleurs de saison, en hiver les tiges sont plus courtes. \n Je vous propose donc des compositions sur mousse…. Ou des fleurs séchées issues également du jardin`}</h2>
      {isMobile ? (
        
        <div className="embla" ref={emblaRef}>
          {cardData.length != 0 ?
      <div className="embla__container">
          <SelectionCards nom={cardData[0]["texte_compositions"]} price={cardData[0]["prix_compositions"]} srcImg={cardData[0]["img_path"]} />
          <SelectionCards nom={cardData[1]["texte_compositions"]} price={cardData[1]["prix_compositions"]} srcImg={cardData[1]["img_path"]} />
          <SelectionCards nom={cardData[2]["texte_compositions"]} price={cardData[2]["prix_compositions"]} srcImg={cardData[2]["img_path"]} />
          <SelectionCards nom={cardData[3]["texte_compositions"]} price={cardData[3]["prix_compositions"]} srcImg={cardData[3]["img_path"]} />
          <SelectionCards nom={cardData[4]["texte_compositions"]} price={cardData[4]["prix_compositions"]} srcImg={cardData[4]["img_path"]} />
          <SelectionCards nom={cardData[5]["texte_compositions"]} price={cardData[5]["prix_compositions"]} srcImg={cardData[5]["img_path"]} />
          <SelectionCards nom={cardData[6]["texte_compositions"]} price={cardData[6]["prix_compositions"]} srcImg={cardData[6]["img_path"]} />
          <SelectionCards nom={cardData[7]["texte_compositions"]} price={cardData[7]["prix_compositions"]} srcImg={cardData[7]["img_path"]}/>
          
          </div> : <div></div>}
    </div>
      ) : (
        <div className='centerDiv'>
          {cardData.length != 0 &&
        <div className="card-selection-section">
          <SelectionCards nom={cardData[0]["texte_compositions"]} price={cardData[0]["prix_compositions"]} srcImg={cardData[0]["img_path"]} />
          <SelectionCards nom={cardData[1]["texte_compositions"]} price={cardData[1]["prix_compositions"]} srcImg={cardData[1]["img_path"]} />
          <SelectionCards nom={cardData[2]["texte_compositions"]} price={cardData[2]["prix_compositions"]} srcImg={cardData[2]["img_path"]} />
          <SelectionCards nom={cardData[3]["texte_compositions"]} price={cardData[3]["prix_compositions"]} srcImg={cardData[3]["img_path"]} />
          <SelectionCards nom={cardData[4]["texte_compositions"]} price={cardData[4]["prix_compositions"]} srcImg={cardData[4]["img_path"]} />
          <SelectionCards nom={cardData[5]["texte_compositions"]} price={cardData[5]["prix_compositions"]} srcImg={cardData[5]["img_path"]} />
          <SelectionCards nom={cardData[6]["texte_compositions"]} price={cardData[6]["prix_compositions"]} srcImg={cardData[6]["img_path"]} />
          <SelectionCards nom={cardData[7]["texte_compositions"]} price={cardData[7]["prix_compositions"]} srcImg={cardData[7]["img_path"]}/>
          </div>}
          </div>
      )}
    </div>
  );
}