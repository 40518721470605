import StoryCardLeft from "../components/Storytelling/StoryCardLeft";
import StoryCardRight from "../components/Storytelling/StoryCardRight";
import Bandeau from "../components/General/BandeauImgActivity";
import Path from "../components/General/Path";
import svg1 from '../images/SVG/greenLines3.svg';
import svg2 from '../images/SVG/greenBubble.svg';
import svg3 from '../images/SVG/greenShape.svg';
import svg4 from '../images/SVG/pinkShape.svg';
import svg5 from '../images/SVG/pinkBubble.svg';
import svg6 from '../images/SVG/pinkLines3.svg';
import svg7 from '../images/SVG/greenBubble.svg';
import svg8 from '../images/SVG/greenLines3.svg';
import svg9 from '../images/SVG/greenShape.svg';
import svg10 from '../images/SVG/pinkLines3.svg';
import svg11 from '../images/SVG/pinkBubble.svg';
import svg12 from '../images/SVG/pinkShape.svg';
import contact from "../images/Banniere/banniere5.jpg";
import '../css/StoryCard.css';
import Footer from "../components/General/FooterElements";
import { useState, useEffect } from "react";
import { getQuiSommesNous } from "../lib";
export default function AProposPage() {
    /*let shortText = ["« Mais qu’est ce que t’es venue foutre au fin fond de la Normandie pour faire pousser des palmiers ? » Question récurrente car c’est un virage à 180°C qui s’est ouvert à moi début 2022 ... ",
    "Comment ça des palmiers en Normandie ?! Eh oui ! Nos palmiers sont produits dès la graine en Normandie, Ils supportent très bien le gel et vivent sans problème dans notre fameux climat tempéré Normand ...",
    "Cela faisait déjà plusieurs années que je m’intéressais aux bouquets et aux créations végétales, mais surtout que je partageais des bouquets personnalisés sur les réseaux avec mes amis pendant le COVID, puis en vrai ...",
    "La biodiversité ça s’apprend ! et il n’y a rien de mieux que de venir découvrir sur le terrain les bienfaits et les couleurs des plantes qui nous entourent.",
]*/
const [shortText, setShortText] = useState(["","","",""]);
const [imgPath, setImgPath] = useState(["","","",""]);
const [initData, setInitData] = useState(true);

  useEffect(() => {
    getQuiSommesNous().then(result => {
        const text_annick = result.data.find(evenement => evenement.libelle_sujet === "Annick");
        const text_mini_foret = result.data.find(evenement => evenement.libelle_sujet === "Mini_foret");
        const text_palmeraie = result.data.find(evenement => evenement.libelle_sujet === "Palmeraie");
        const text_vision_et_missions = result.data.find(evenement => evenement.libelle_sujet === "Vision_et_missions");
      setShortText([text_annick.texte_court,text_mini_foret.texte_court,text_palmeraie.texte_court,text_vision_et_missions.texte_court])
      setImgPath([text_annick.img_path,text_mini_foret.img_path,text_palmeraie.img_path,text_vision_et_missions.img_path])
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
   
  }, [initData]);

    return (
        <div className="a-propos-page">
            <Bandeau imgFond={contact} titre="Qui Sommes-Nous ?"/>
            <main>
                <Path lastPage="Accueil" lastPagePath="/" page="Qui sommes nous ?" pagePath="/aPropos"/>
                <div className="story-svg-holder">
                    <img className='deco-story-page-1' src={svg1} alt="decoration"/>
                </div>
                <div className="story-svg-holder">
                    <img className='deco-story-page-2' src={svg2} alt="decoration"/>
                </div>
                <div className="story-svg-holder">
                    <img className='deco-story-page-3' src={svg3} alt="decoration"/>
                </div>
                <StoryCardRight
                    titleText="Annick"
                    cardText={shortText[0]}
                    onClick="/aPropos/Annick"
                    imgLink={imgPath[0]}
                />
                <div className="story-svg-holder">
                    <img className='deco-story-page-4' src={svg4} alt="decoration"/>
                </div>
                <div className="story-svg-holder">
                    <img className='deco-story-page-5' src={svg5} alt="decoration"/>
                </div>
                <div className="story-svg-holder">
                    <img className='deco-story-page-6' src={svg6} alt="decoration"/>
                </div>
                <StoryCardLeft
                    titleText="La Palmeraie"
                    cardText={shortText[1]}
                    onClick="/aPropos/Palmeraie"
                    imgLink={imgPath[1]}
                />
                <div className="story-svg-holder">
                    <img className='deco-story-page-7' src={svg7} alt="decoration"/>
                </div>
                <div className="story-svg-holder">
                    <img className='deco-story-page-8' src={svg8} alt="decoration"/>
                </div>
                <div className="story-svg-holder">
                    <img className='deco-story-page-9' src={svg9} alt="decoration"/>
                </div>
                <StoryCardRight
                    titleText="Vision et Missions"
                    cardText={shortText[2]}
                    onClick="/aPropos/Vision"
                    imgLink={imgPath[2]}
                />
                <div className="story-svg-holder">
                    <img className='deco-story-page-10' src={svg10} alt="decoration"/>
                </div>
                <div className="story-svg-holder">
                    <img className='deco-story-page-11' src={svg11} alt="decoration"/>
                </div>
                <div className="story-svg-holder">
                    <img className='deco-story-page-12' src={svg12} alt="decoration"/>
                </div>
                <StoryCardLeft
                    titleText="Projet Mini Forêt"
                    cardText={shortText[3]}
                    onClick="/aPropos/Foret"
                    imgLink={imgPath[3]}
                />
            </main>
            <Footer/>
        </div>
    )
}
