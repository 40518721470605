import Bandeau from "../components/General/BandeauImgActivity";
import Path from "../components/General/Path";
import contact from "../images/Banniere/banniere2.jpg";
import SectionEvenement1 from "../components/services/Sections/EvenementSection1"
import SectionEvenement2 from "../components/services/Sections/EvenementSection2"
import Footer from "../components/General/FooterElements";
import { getEvenements } from "../lib";
import { useState, useEffect } from "react";


export default function EvenementPage() {
    const [initData, setInitData] = useState(true);
   /*var textvar = ["Fleur de Palmier propose des locations de plantes pour le temps d’un événement afin d’émerveiller vos plus beaux moments avec une touche exotique.  Nous vous proposons différentes façons de mettre vos fleurs en scène : Broches, Diadèmes, Bouquets. (Une belle robe accompagnée d’un beau bouquet, on a rarement vu une union aussi harmonieuse, ah, si, sans doute la vôtre :D).", 
   "Accompagner la vie de végétal, c’est également vous accompagner lors des moments tristes. Les bouquets funéraires sont composés selon vos souhaits et le message que vous voulez transmettre au défunt. Les tombes paysagères quant à elles remplacent la pierre tombale par un mini jardin en lien avec la personnalité du défunt. ",
   "Après avoir découvert ces créations dans les pays nordiques, je suis tout de suite tombée amoureuse de l’idée de remplacer le construit par des plantes qui vont évoluer et revitaliser ces espaces souvent délaissés. Nous choisissons ensemble les plantes et je me charge en lien avec des professionnels de trouver le meilleur accord entre la tombe et les plantes. Nous avons la possibilité de travailler sur des surfaces allant de 100cmX60cm à 2mX1m. L",
   "La biodiversité ça s’apprend ! et il n’y a rien de mieux que de venir découvrir sur le terrain la vie des plantes qui nous entourent. Que ce soit à la palmeraie ou dans le projet de la micro-forêt, le but est d’apprendre grâce à des ateliers pédagogiques simples la beauté et la diversité du cycle des fleurs. Cela peut donc aller de l’entretien de mini jardin, à la création de bouquet ou d’une composition en ayant fait pousser soi-même ses ﬂeurs, ou encore comprendre le cycle de reproduction de ces dernières. ",
   "Ces projets sont donc tout particulièrement adressé aux écoles et à nos jeunes pousses. Les futures générations auront fort à faire avec l’urgence climatique, je suis heureuse de pouvoir commencer à partager et dialoguer avec eux sur principe d’écologie. ",
   "Vous avez un espace chez vous à aménager, un endroit que vous voulez transformer ou qui ne vous plait plus? vous m'envoyer une photo, les dimensions ou je me déplace et vous effectue un devis pour une 'composition paysagère' avec les plantes que je produis ( travail de plantation et mise en place possible/ vente des plantes seules également)"
]*/

const [textvar, setTextvar] = useState([]);
const [imgVar, setImgVar] = useState([]);

useEffect(() => {
    getEvenements().then(result => {
        const Bouquets_funeraires = result.data.find(evenement => evenement.libelle_evenement === "Bouquets_funeraires");
        const Composition_paysagere = result.data.find(evenement => evenement.libelle_evenement === "Composition_paysagere");
        const Mariages_ou_Evenements = result.data.find(evenement => evenement.libelle_evenement === "Mariages_ou_Evenements");
        const Projet_pedagogique_1 = result.data.find(evenement => evenement.libelle_evenement === "Projet_pedagogique_1");
        const Projet_pedagogique_2 = result.data.find(evenement => evenement.libelle_evenement === "Projet_pedagogique_2");
        const Tombes_paysageres = result.data.find(evenement => evenement.libelle_evenement === "Tombes_paysageres");
        const Decoration_vitrine = result.data.find(evenement => evenement.libelle_evenement === "Decoration_vitrine");
        setTextvar([Mariages_ou_Evenements.texte_evenement, Bouquets_funeraires.texte_evenement,Tombes_paysageres.texte_evenement, Projet_pedagogique_1.texte_evenement,Projet_pedagogique_2.texte_evenement, Composition_paysagere.texte_evenement, Decoration_vitrine.texte_evenement])
        setImgVar([Mariages_ou_Evenements.img_path, Bouquets_funeraires.img_path,Tombes_paysageres.img_path, Projet_pedagogique_1.img_path,Projet_pedagogique_2.img_path, Composition_paysagere.img_path, Decoration_vitrine.img_path])
      //setTextvar(result.data)
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [initData]);

    return <div className="evenement-page">
    <Bandeau imgFond={contact} titre="Événements"/>
    <main className="page-tombe">
        <Path lastPage="Accueil" lastPagePath="/" page="Événements" pagePath="/evenement"/>
        <h3 className="intro-evenements">Fleur de Palmier vous accompagne dans tous vos événements importants, du bourgeon au fanage. Découvrez toutes les possibilités ﬂeuries ci-dessous :</h3>
        <SectionEvenement1 title1={"Mariages ou Événements : "}  src1={imgVar[0]}  text1={textvar[0]}  />
    <SectionEvenement2  title2={"Bouquets funéraires et Tombes Paysagères "}  src2={imgVar[1]} text2={textvar[1]} />
    <SectionEvenement1 title1={""}  src1={imgVar[2]}  text1={textvar[2]}  />
    <SectionEvenement2  title2={"Projet Pédagogique "}  src2={imgVar[3]} text2={textvar[3]} />
    <SectionEvenement1 title1={""}  src1={imgVar[4]}  text1={textvar[4]}  />
    <SectionEvenement2  title2={"Composition paysagère"}  src2={imgVar[5]} text2={textvar[5]} />
    <SectionEvenement1 title1={"Décoration de vitrines"}  src1={imgVar[6]}  text1={textvar[6]}  />

    </main>
    <Footer/>
</div>
}