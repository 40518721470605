import svg1 from '../../images/SVG/greenLines.svg';
import svg2 from '../../images/SVG/purpleLines.svg';
import svg3 from '../../images/SVG/purpleBubble.svg';
import svg4 from '../../images/SVG/pinkLines.svg';
import svg5 from '../../images/SVG/pinkBubble.svg';
import svg6 from '../../images/SVG/greenShape.svg';
import ContainerEvenement from './ContainerEvenement';
//
import { useEffect, useRef, useState } from 'react';
import { getActualites } from '../../lib';
import { isLocalHost } from '../../config';
export default function SlideShow () {
    var textEvenement=`Nous sommes ravis de vous annoncer que la serre de fleur de palmier à Romagny sera exceptionnellement ouverte pour vous le dimanche 17 décembre, le samedi 23 décembre, ainsi que le 24 décembre au matin. \nProfitez de ces journées spéciales pour découvrir nos magnifiques compositions. \nNous vous attendons avec impatience !`
    const [data, setData] = useState([

    ]);
    const [initData, setInitData] = useState(true);
   useEffect(() => {
    if(!isLocalHost){
      getActualites().then(result => {
        setData(result.data)
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
      
      
    }, [initData]);
    /*var data={
        "evenements": [
          {
            "texte": textEvenement,
            "image_path": imgActu
          },
          {
            "texte": "Description de l'événement 2.",
            "image_path": imgActu
          },
          {
            "texte": "Description de l'événement 3.",
            "image_path": imgActu
          }
          // Ajoutez d'autres événements au besoin
        ]
      }*/


      //
      const scrollContainerRef = useRef(null);
      const [scrollLeft, setScrollLeft] = useState(0);
      const [indexEvenement, setIndexEvenement] = useState(0);
      var longueurData = data.length
    
      useEffect(() => {
        const handleWheel = (e) => {
         
          setScrollLeft((prevScrollLeft) => prevScrollLeft + e.deltaY);
        };
    
        const container = scrollContainerRef.current;
        container.addEventListener('wheel', handleWheel);
    
        return () => {
          container.removeEventListener('wheel', handleWheel);
        };
      }, []);
    
      useEffect(() => {
        const intervalId = setInterval(() => {
            if(indexEvenement<longueurData-1){
                setIndexEvenement(indexEvenement+1)
                
                const container = scrollContainerRef.current;
          const newScrollLeft = scrollLeft + window.innerWidth; // Ajustez la valeur selon vos besoins
          setScrollLeft(newScrollLeft);
          container.scrollTo({ left: newScrollLeft, behavior: 'smooth' });
            }else{
                setIndexEvenement(0)
                
                const container = scrollContainerRef.current;
          const newScrollLeft = 0; // Ajustez la valeur selon vos besoins
          setScrollLeft(newScrollLeft);
          container.scrollTo({ left: newScrollLeft, behavior: 'smooth' });
            }
          
        }, 10000);
    
        return () => {
          clearInterval(intervalId);
        };
      }, [scrollLeft]);


      function changeEvenementView(indexEvnmt) {
        const container = scrollContainerRef.current;
          const newScrollLeft = indexEvnmt * window.innerWidth; // Ajustez la valeur selon vos besoins
          setScrollLeft(newScrollLeft);
          container.scrollTo({ left: newScrollLeft, behavior: 'smooth' });
        
    }

  //

   return (
        <div className="slider-card-evenement">
            <h1 className='titre-slider-evenement'>Nos Actualités!</h1>
            <div>
                <div className='container-evenement-list' ref={scrollContainerRef}>
                {data.map((evenement, index) => (
                  <ContainerEvenement key={index} textEvnmt={evenement["texte"]} imgActu={evenement['image_path']} />
                ))}
                </div>
                <div className='container-button-evenement'>
                {data.map((evenement, index) => (
                  <button onClick={() => changeEvenementView(index)} ></button>
                ))}
                </div>
            </div>
            <img className='decoration-slider1' src={svg1} alt="decoration"/>
            <img className='decoration-slider6' src={svg6} alt="decoration"/>
            <img className='decoration-slider2' src={svg2} alt="decoration"/>
            <img className='decoration-slider3' src={svg3} alt="decoration"/>
            <img className='decoration-slider4' src={svg4} alt="decoration"/>
            <img className='decoration-slider5' src={svg5} alt="decoration"/>
        </div>
    );
}


