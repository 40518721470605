import { Axios } from "../config";

export const getPlantes = async () => {
    try {
        const res = await Axios.get("plantes");
       console.log(Axios.getUri)
        return { data: res.data, error: false };
    }   catch (error) {
        return { data: [], error: true };
    }
};

export const getImagesInstagram = async () => {
  try {
      const res = await Axios.get("instagram");
      
     
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};

export const getBouquets = async () => {
  try {
      const res = await Axios.get("bouquets");
     
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};

export const getProduits = async () => {
  try {
      const res = await Axios.get("produits");
      
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};

export const getCompositions = async () => {
  try {
      const res = await Axios.get("compositions");
      
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};
export const getActualites = async () => {
  try {
      const res = await Axios.get("actualites");
      
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};
export const getEvenements = async () => {
  try {
      const res = await Axios.get("evenements");
      
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};
export const getPretPlantes = async () => {
  try {
      const res = await Axios.get("pret_plantes");
      
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};
export const getQuiSommesNous = async () => {
  try {
      const res = await Axios.get("qui_sommes_nous");
      
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};
export const getQSNAnnick = async () => {
  try {
      const res = await Axios.get("annick");
      
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};
export const getQSNMiniForet = async () => {
  try {
      const res = await Axios.get("mini_foret");
      
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};
export const getQSNPalmeraie = async () => {
  try {
      const res = await Axios.get("palmeraie");
      
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};
export const getQSNVisionMissions = async () => {
  try {
      const res = await Axios.get("vision_et_missions");
      
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};
export const getBouquetAboPerso = async () => {
  try {
      const res = await Axios.get("bouquet_abo_perso");
      
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};

export const getImagesBouquetAboPerso = async () => {
  try {
      const res = await Axios.get("img_bouquet_abo_perso");
      
      return { data: res.data, error: false };
  }   catch (error) {
      return { data: [], error: true };
  }
};


export const postClient = async (data) => {
  try {
   

   
    
    const res = await Axios.post('contact', data).then(res=>{
     
    });
  } catch (err) {
    console.error(err);
  }
};

export const postCommande = async (data) => {
  
  try {
    const res = await Axios.post('commande', data).then(res=>{
     
    });
  } catch (err) {
    console.error(err);
  }
};
