import StoryTextLeft from "../components/Storytelling/StoryTextLeft";
import StoryTextRight from "../components/Storytelling/StoryTextRight";
import StoryText from "../components/Storytelling/StoryText";
import Links from "../components/General/Links";
import ScrollTopButton from "../components/General/ScrollTopButton";
import InfosPratiques from "../components/General/InfosPratiques";
import Copyright from "../components/General/Copyright";
import svg1 from '../images/SVG/pinkShape.svg';
import svg2 from '../images/SVG/greenLines2.svg';
import svg3 from '../images/SVG/greenShape.svg';
import svg4 from '../images/SVG/pinkLines3.svg';
import svg5 from '../images/SVG/pinkBubbleFlat.svg';
import svg6 from '../images/SVG/greenLinesRound.svg';
import svg7 from '../images/SVG/greenBubbleSharp.svg';
import svg8 from '../images/SVG/pinkLinesSingle.svg';
import '../css/StoryCard.css';
import StoryPath from "../components/Storytelling/StoryPath";
import Footer from "../components/General/FooterElements";
import { getQSNMiniForet } from "../lib";
import { useState, useEffect } from "react";
export default function AProposForetPage() {
    /*let dataText = [
        "Fleur de Palmier se veut comme une alternative aux modèles économiques des géants de l’industrie qui importent leurs plantes exotiques d’Italie ou d’Espagne, et leurs fleurs du Kenya ou d’Hollande. Mais l’engagement de Fleur de Palmier va encore plus loin avec la création d’une micro-forêt.",
        "C’est un projet qui me tient à cœur personnellement mais que je n’avais pas encore pu réaliser. Tous les 100€ de plantes ou palmiers achetées, c’est 1€ qui sera investi dans l'achat d'arbres avec pour but de faire éclore ce petit ecosysteme sur une parcelle de 300m² prévue pour cela.",
        "L’objectif à terme est de pouvoir récréer une biodiversité autorégulée dans cette forêt qui participera à une baisse de température de 1 à 3°C et de pouvoir réaliser des projets pédagogiques avec les écoles voisines afin de sensibiliser nos jeunes pousses à l’importance du végétal. "
    ]*/

    const [dataText, setDataText] = useState(["","","",""]);
const [initData, setInitData] = useState(true);

  useEffect(() => {
    getQSNMiniForet().then(result => {
      setDataText([result.data[0].texte_1,result.data[0].texte_2,result.data[0].texte_3])
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [initData]);
    return (
        <div className="a-propos-ss-page">
            <StoryPath page="Projet Mini Forêt" pagePath="/aPropos/Foret"/>
            <div className="a-propos-ss-page-computer">
                <div className="story-svg-holder">
                    <img className='deco-story-palm-1' src={svg1} alt="decoration"/>
                </div>
                <div className="story-svg-holder">
                    <img className='deco-story-palm-2' src={svg2} alt="decoration"/>
                </div>
                <span className="a-propos-sub-page-title-2">
                    Projet Mini Forêt
                </span>
                <main>
                    <StoryTextLeft
                        cardText={dataText[0]}
                    />
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-3' src={svg3} alt="decoration"/>
                    </div>
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-4' src={svg4} alt="decoration"/>
                    </div>
                    <StoryTextRight
                        cardText={dataText[1]}
                    />
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-5' src={svg5} alt="decoration"/>
                    </div>
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-6' src={svg6} alt="decoration"/>
                    </div>
                    <StoryTextLeft
                        cardText={dataText[2]}
                    />
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-7' src={svg7} alt="decoration"/>
                    </div>
                    <div className="story-svg-holder">
                        <img className='deco-story-palm-8' src={svg8} alt="decoration"/>
                    </div>
                    
                </main>
            </div>
            <div className="a-propos-ss-page-phone">
                <main>
                    <StoryText
                        titleText="Projet Éducatif Mini Forêt"
                        cardText={dataText[0]+" "+dataText[1]+" "+dataText[2]}
                        />
                </main>
            </div>
            <Footer/>
        </div>
    )
}