import { useState, useEffect } from "react";
import CardAnnuaire from "./CardAnnuaire";
import { getPlantes } from "../../lib";
import {annuaireData} from "../../db/data"

export default function ListCards(props) {
  
  //var des données des plantes affiché dans la page
  const [currentPlantes, setCurrentPlantes] = useState([]);
  //var des plantes selon le filtre
  const [listPlantes, setListPlantes] = useState([]);
  //var de toutes les plantes en local pour éviter de les appeler toute le temps on les charges seulement au chargeement de la page
  const [plantes, setPlantes] = useState([]);
  /*nombre de pages*/
  const [pages, setPages] = useState([]);
  //on set up toutes les plantes sans filtre au début. 
  const [initData, setInitData] = useState(true);
  useEffect(() => {
    getPlantes().then(result => {
      console.log(result.data)

      setPlantes(result.data.filter(item => !item.img_path.includes('test')))
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
    setListPlantes(plantes);
  }, [initData]);
  //on affiche seulement les 12 1ere plantes sur la page 1
  useEffect(() => {
    setCurrentPlantes(listPlantes.slice(0, 12)); 
  }, [listPlantes]);
  //permet de display les 12 plantes trié sur la page 1-2-n
  useEffect(() => {
    const listPage = [];
    for (let step = 0; step < Math.floor(plantes.length / 12) + 1; step++) {
      listPage.push({ id: step + 1 });
    }
    setPages(listPage);
  }, [plantes.length]);

  /*filtres*/
  const inputText = props.InputText;
  const filter1 = props.Filter1;
  const filter2 = props.Filter2;
  //permet de filtrer les plantes seulon plusieurs catégories
  useEffect(() => {
    const filteredList = plantes.filter((item) => {
        const name = item.name.toLowerCase(); // convert to lowercase
        const input = inputText.toLowerCase(); // convert to lowercase
      if (name.includes(inputText) || input === "") {
        if (
          item.vegetal_family.includes(filter1) ||
          filter1 === "Tout" ||
          filter1 === undefined
        ) {
          if (
            item.location_category.includes(filter2) ||
            filter2 === "Tout" ||
            filter2 === undefined
          ) {
            return true;
          }
        }
      }
      return false;
    });

    setListPlantes(filteredList);

    // Calculate number of pages
    const pageCount = Math.ceil(filteredList.length / 12);
    const pages = [];
    for (let i = 1; i <= pageCount; i++) {
      pages.push({ id: i });
    }
    setPages(pages.length > 0 ? pages : []);

    setCurrentPlantes(filteredList.slice(0, 12));
  }, [ inputText, filter1, filter2, plantes]);

  function setPageContent(index) {
    setCurrentPlantes(listPlantes.slice((index - 1) * 12, index * 12));
  }

  const topFunction = () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
  }
  return (
    <section className="annuaire-cards">
      <ul className="list-card-annuaire">
      {currentPlantes.map((plante) => (
          <li className="card-li" key={plante.id}>
            <CardAnnuaire
              nom={plante.name}
              price={plante.price}
              description={plante.short_description}
              srcImg={plante.img_path}
            />
          </li>
))}
      </ul>
      <div>
        {pages.map((pages) => (
          <button
            className="btn btn-pages"
            onClick={() => {setPageContent(pages.id); topFunction();}}
          >
            {pages.id}
          </button>
        ))}
      </div>
    </section>
  );
}
