import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player'

export default function PastilleVideo() {
    const [showPrice, setShowPrice] = useState(false);
  const cardWrapperRef = useRef(null); // Reference to the outer card div
  const videoDiv = useRef(null); // Reference to the flyer background

  // Function to toggle video visibility
  function handleClick() {
    setShowPrice(true);

  }

  // Handle clicks outside the card wrapper to close the video
  const handleClickOutside = (event) => {
    if (
        videoDiv.current && 
      !videoDiv.current.contains(event.target)
    ) {
      setShowPrice(false); // Hide the video if click is outside the card wrapper
    }
  };

  useEffect(() => {
    // Add event listener for detecting clicks outside the card wrapper
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Prevent click propagation inside the flyer-background to avoid closing the video when clicked inside
  const handleFlyerClick = (e) => {
    e.stopPropagation(); // Prevent the event from bubbling up to the card wrapper
  };

  return (
    <div className="card-wrapper" ref={cardWrapperRef} onClick={handleClick}>
      {/* Only show the flyer (video) when showPrice is true */}
      {showPrice && (
        <div
          className="flyer-background"
          onClick={handleFlyerClick} // Prevent propagation here
        >
          <video autoPlay ref={videoDiv} className="flyer" width="1080" height="720" controls >
            <source src="images/video/test.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}

      <div className="pastille-video">
        <h2>Video</h2>
      </div>
    </div>
  );

  
}
